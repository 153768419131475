<template>
    <div class="site-signin">
        <form action="https://eztimerental.site/login/authenticate" autocomplete="off" method="post">
            <md-card>
                <md-card-header>
                    <h2>
                        <span>Sign In</span>
                    </h2>
                </md-card-header>
                <md-card-content>
                    <md-input-container>
                        <label for>Email Address</label>
                        <md-input v-model="username" name='username'></md-input>
                    </md-input-container>
                    <md-input-container>
                        <label for>Password</label>
                        <md-input type="password" v-model="password" name='password'></md-input>
                    </md-input-container>
                </md-card-content>
                <md-card-actions>
                    <submit class="submitButton" icon="save" text="Submit"></submit>
                </md-card-actions>
            </md-card>
            <div>
                <a class="forgot-password" href="https://eztimerental.site/forgotPassword/req" style="margin-left:10px;margin-right:10px;">Forgot your password?</a>
                <router-link to="/admin/sites/signup">Sign Up</router-link>
            </div>
        </form>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import Submit from "@/components/Submit";

export default {
    components: {
        Submit
    },
    data() {
        return {
            customer: null,
            username: "",
            password: ""
        };
    },
    computed: {
        ...mapGetters({
            user: "user/user"
        })
    },
    methods: {
        async getCustomer() {
            let result = await this.$store.dispatch("crud/get", {
                api: "s5/customer/get",
                params: {
                    email: this.user.email
                }
            });
            this.customer = result;
        },
        async getCustomers() {
            let result = await this.$store.dispatch("crud/get", {
                api: "s5/customer",
                params: {
                    email: this.user.email
                }
            });
            this.customer = result;
        }
    },
    async created() {
        this.getCustomers();
        await this.getCustomer();
        if (!this.customer) {
            this.$store.commit("setMessage", "Please Sign Up First");
            // this.$router.push("/admin/sites/signup");
        }
    }
};
</script>

<style lang="less">
.site-signin {
    max-width: 1000px;
    margin: auto;
}
</style>
